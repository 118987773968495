@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@include mat.core();

$maderopalette: (
  50: #faf8ed,
  100: #f2ecd3,
  200: #eae0b5,
  300: #e1d497,
  400: #daca81,
  500: #d4c16b,
  600: #cfbb63,
  700: #c9b358,
  800: #c3ab4e,
  900: #b99e3c,
  A100: #ffffff,
  A200: #fff5d4,
  A400: #ffeaa1,
  A700: #ffe488,
  contrast: (
    50: #ffffff,
    100: #ffffff,
  ),
);

$madero-typography: mat.define-typography-config(
  $font-family: "Montserrat, sans-serif",
);

$primary: mat.define-palette($maderopalette, 500);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    typography: $madero-typography,
    density: 0,
  )
);

@include mat.core-theme($theme);
@include mat.button-theme($theme);
@include mat.button-typography($madero-typography);
@include mat.typography-hierarchy($madero-typography);
@include mat.all-component-themes($theme);

:root {
  --opacity-8: 0.8;
  --opacity-6: 0.6;

  --white: #ffffff;
  --black: #000000;

  --transparent: transparent;
  --primary: #d4c16b;
  --accent: #ffffff;
  --primary-smoth: rgba(212, 193, 107, 0.2);
  --primary-smoth-8: rgba(212, 193, 107, 0.8);

  --primary-opacity-8: rgba(5, 64, 211, var(--opacity-8));

  --support-color-1: #03b6d6;
  --support-color-1-alpha: #03b6d639;

  --neutral-default: #efefef;
  --neutral-default-opacity-6: rgba(239, 239, 239, var(--opacity-6));
  --neutral-default-opacity-8: rgba(239, 239, 239, var(--opacity-8));
  --neutral-darkest: #54565b;
  --neutral-dark: #686868;
  --neutral-medium: #cdcdcd;
  --neutral-light: #ececec;
  --neutral-default-loading: rgba(0, 0, 0, 0.062);

  --suport-1: #6b6b6b;
  --suport-2: #16b138;
  --suport-3: #ffb400;
  --suport-4: #ee3f4d;
  --suport-5: #7ad16b;
  --suport-6: #a129d8;

  --theme-background: #ffffff;
  --theme-color: #000000;

  // ==== Layout =====
  --max-width-sidebar: 1060px;

  // ========= reset default ===============
  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h4 {
    font-size: 1rem;
  }

  *,
  html {
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
  }

  body {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: var(--theme-color);
  }
}

// *::-webkit-scrollbar {
//   width: 4px;
// }

// *::-webkit-scrollbar-track {
//   background: #cccccc;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: #ececec65;
//   border-radius: 20px;
//   border: 4px solid #cccccc;
// }

.c-content-page {
  padding: 16px 16px 0;
  &__header {
    color: var(--neutral-darkest);
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    hr {
      border: 1px solid var(--primary);
    }

    p {
      color: var(--neutral-dark);
      font-size: 0.9rem;
      margin-bottom: 16px;
    }
  }
}

.c-header-title {
  padding: 16px;

  &__title {
    color: var(--neutral-dark);
    font-weight: 900 !important;
  }

  &__line-title {
    border: 1px solid var(--primary);
  }

  &__subtitle {
    color: var(--neutral-dark);
  }
}

.c-btn-spinner {
  display: flex;
  align-items: center;

  &__spinner {
    padding-right: 16px;
    width: 30px !important;
    height: 30px !important;
  }
}

.btn-default {
  border-radius: 99px;
  height: 51px;
  color: var(--white) !important;
  width: 100%;

  &__text {
    display: flex;
    align-items: center;

    &--icon {
      padding-right: 8px;
    }
  }

  &__loader {
    display: flex;
    align-items: center;

    &--spinner {
      padding-right: 16px;
      width: 30px !important;
      height: 30px !important;
    }
  }
}

.c-btn {
  width: 100%;
  background-color: var(--neutral-default) !important;
  border-radius: 4px !important;
  display: flex;
  height: 50px !important;
  font-size: 1rem !important;
  transition: 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  &--spinner {
    margin-right: 8px;
    width: 25px !important;
    height: 25px !important;
    background-color: transparent !important;

    circle {
      stroke: #fff !important;
    }
  }

  &--primary {
    background-color: var(--black) !important;
    color: var(--white) !important;
  }

  &--secundary {
    background-color: var(--white) !important;
    border: 2px solid var(--black) !important;
    color: var(--black) !important;
  }

  &--warn {
    background-color: var(--suport-4) !important;
    color: var(--white) !important;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--neutral-default-opacity-8) !important;
  }
}

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 900;
  background-color: var(--neutral-default-loading);

  img {
    width: 50px;
  }
}

.readonly-div {
  pointer-events: none;
  opacity: 0.6;
}
